.imageContainer {
  position: relative;
}

.imageContainer:hover > .editBtn {
  display: block;
}

.imageContainer img:hover {
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  -o-filter: blur(1px);
  filter: blur(1px);
  transform: scale(1.03);
}

.editBtn {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
}
