.form-wrapper {
  height: auto;
  max-height: 600px;
  transform: scaleY(1);    
  transform-origin: top;
  transition: all 0.25s ease;
}

.form-wrapper.form-collapsed {
  transform: scaleY(0);
  max-height: 0;
}

/* File Uploads */
.drop-zone {
  height: 250px;
  width: 250px;
  border: 1px solid #aeaeae;
  border-radius: 50%;
  color: #afafaf;
}

.Upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  align-items: flex-start;
  justify-items: flex-start;
  overflow-y: auto;
  margin-top: 15px;
}

.Actions {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 12px;
}

.Filename {
  font-size: 16px;
  color: #555;
  z-index: 2;
}

.FileRowWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.FileRow {
  width: 100%;
  padding: 0 0 0 5px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  border: 1px solid #c2dac2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ProgressBar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(210, 243, 210, 0.3);
}

.UploadCompleteIcon {
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 90%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: #336933;
}

.no-focus.btn:focus, .no-focus.btn.focus {
  outline: 0;
  box-shadow: none;
}